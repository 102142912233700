import inViewport from 'in-viewport';

export function initWelcomeView() {
    const nav = document.querySelector('#js-navigation');
    const navToggle = document.querySelector('#js-navigation-toggle');
    const hero = document.querySelector('#js-hero');
    const scrollingHint: HTMLElement = document.querySelector('#js-scrolling-hint');
    const storylineItems = document.querySelectorAll('.js-storyline-item');

    // Set needed class for distinguish touch / no-touch
    if (!('ontouchstart' in window)) {
        document.querySelector('body').classList.add('no-touch');
    }

    // Nav Handling
    if (navToggle && nav) {
        navToggle.addEventListener('click', () => {
            nav.classList.toggle('nd-navigation--is-active');
        });
    }

    if (hero) {
        hero.classList.add('nd-hero--is-visible');
    }

    // Handle Scrolling-LInk
    if (scrollingHint) {
        scrollingHint.addEventListener('click', evt => {
            evt.preventDefault();
            const target = document.querySelector(scrollingHint.getAttribute('href'));
            scrollingHint.blur();

            if (target) {
                target.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        });
    }

    // Show storyline Items on viewport enter
    if (storylineItems) {
        storylineItems.forEach(storylineItem => {
            inViewport(storylineItem, { offset: -150 }, () => {
                setTimeout(() => {
                    storylineItem.classList.add('nd-storyline__item--is-visible');
                }, 250);
            });
        });
    }
}
