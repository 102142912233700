import LazyLoad from 'vanilla-lazyload';

export function initLazyImages() {
    /**
     * Helper to find closest parent selector of element
     * @param {string} element
     * @param {HTMLElement} selector
     * @returns {HTMLElement}  If found, parent
     */
    const getClosest = (element, selector) => {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (this: Element, s) {
                    let matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {}
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; element && element !== document; element = element.parentNode) {
            if (element.matches(selector)) return element;
        }
        return null;
    };

    // Initialize Lazyload
    new LazyLoad({
        callback_loaded: el => {
            const parentLazy = getClosest(el, '.nd-lazy-image');
            if (parentLazy) {
                parentLazy.classList.add('nd-lazy-image--loaded');
            }
        }
    });
}
